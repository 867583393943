export default [
  {
    title: "إدارة المركز",
    icon: "HomeIcon",
    // tag: "2",
    tagVariant: "light-warning",
    children: [
      {
        title: "الإستقبال",
        route: "reception",
        sub_router:"halls-details"
        // icon:"SquareIcon"
      },
      {
        title: "المكتب",
        route: "office",
      },
      
      {
        title: "إشغال القاعات",
        route: "halls-occupancy",

      },

      {
        title: "الطلبات",
        children: [
          {
            title: "طلبات الأرشيف",
            route: "requests-archives",
          },
        ],
      },
      {
        title: "الحجوزات",
        children: [
          {
            title: "الإجراءات",
            route: "bookings-procedure",
          },
          {
            title: "استوديو التسجيل",
            route: "recording-studio"
          },
          {
            title: " القاعات",
            route: "bookings-halls",
          },
          {
            title: " الفعاليات",
            route: "bookings-events" ,
            sub_router:"bookings-event"
          },
          {
            title: " النشاطات",
            route: "bookings-activites",
            sub_router:"bookings-activite"
          },
          {
            title: "جلسات التوعية",
            route: "bookings-awareness-sessions",
            sub_router:"booking-awareness-sessions"
          },
          {
            title: "متحف تفاعلي",
            route: "bookings-interactive-museum",
            sub_router:"booking-interactive-museum"
          },
          {
            title: "تدريبات تراثية",
            route: "bookings-training-heritage",
            sub_router: "booking-training-heritage"
          },
        ],
      },

      {
        title: "خدمات المركز",
        route: "manage-services",
        // icon:"SquareIcon"
      },
      {
        title: "التقييم",
        route: "manage-evaluation",
        // icon:"SquareIcon"
      },
      {
        title: "الزوار",
        route: "visitors",
        // icon:"SquareIcon"
      },
      {
        title:"المنتجات التمويلية",
        route:"financial-products"
      }
    ],
  },
];
