export default [
    {
      title: 'الإعدادات',
      icon: 'SettingsIcon',
    //   tag: '2',
      tagVariant: 'light-warning',
      children: [
        {
          title: ' إدارة القاعات ',
          route: 'setting-halls',
        },
        {
          title: ' إدارة المكاتب',
          route: 'setting-office',
        },
        {
          title: 'حول التطبيق',
          route: 'manage-about-app',
        },
        {
          title: 'حول المنارة',
          route: 'manage-about-manara',
        },
        {
          title:"التصنيفات",
          children: [
            {
              title:'تصنيفات الأرشيف',
              route:'archive-categories'
            },
            {
              title:'تصنيفات الأنشطة',
              route:'activites-categories'
            },
            {
              title:'تصنيفات فرص العمل',
              route:'opportunities-categories'
            },
            {
              title:'انواع المرافق السياحية',
              route:'type-tourist-facilities'
            },
            {
              title:'تصنيفات الجولات السياحية',
              route:'tourist-tours-categories'
            },
          ]
        },


        {
          title: 'إصدار التطبيق',
          route: 'manage-versions',
        },
        {
          title: 'إدارة المستخدمين',
          children: [
            {
              title: 'إدارة المستفيدين',
              route: 'manage-beneficiary',
            },
            {
              title: 'إدارة مستخدمين المنارة',
              route: 'manage-user-center',
            },
            {
              title: 'صلاحيات مستخدمين المنارة',
              route: 'manage-role',
            }
          ]
        },
      ],
    },
  ]
  