export default [
 {
  title: 'إدارة التطبيق',
  icon: 'HomeIcon',
  // tag: '2',
  tagVariant: 'light-warning',
  children: [
  {
    title: "الفعاليات",
    icon: "CheckCircleIcon",
    route: "dashboard-events",
  },
  {
    title: "أنشطة المنارة",
    icon: "ActivityIcon",
    route: "dashboard-activite",
  },
  {
    title: "جلسات التوعية",
    route: "awareness-sessions",
  },
  {
    title: "متحف تفاعلي",
    route: "interactive-museum"
  },
  {
    title: "تدريبات تراثية",
    route: "training-heritage"
  },
  {
    title:"خدمات مجتمعية",
    children:[
      {
        title:"مخاتير",
        route:"mayors"
      },
      {
        title:"لجان مجتمعية",
        route:"community-committees"
      }
    ]
  },
  {
    title:"التوعية القانونية",
    children: [

      {
        title: "معلومات عامة",
        route: "general-information",
      },
    ],
  },
  {
    title: "اكتشف المدينة القديمة",
    children: [
      {
        title:"المرافق السياحية",
        route:"tourist-facilities"
      },
      {
        title:"جولات سياحية ذاتية",
        route:"self-tourist-tours"
      },
      {
        title: "المعالم الرئيسية",
        route:"main-attraction"
      }
    ]
  },
  {
    title: "إدارة الفرص",
    icon: "BriefcaseIcon",
    route: "dashboard-opportunities",
  },
  {
    title: "إدارة الإجراءات",
    icon: "SettingsIcon",
    route: "dashboard-procedure",
  },
  {
    title: "إدارة المحاضر الشاغرة",
    icon: "GridIcon",
    route: "dashboard-lecturers",
    sub_router:"lecturers-info"
  },
  {
    title: "الخريطة التفاعلية",
    icon: "MapIcon",
    route: "interactive-map",
  },
  {
    title: "أرشيف",
    icon: "FolderIcon",
    route: "archives",
  },
  {
    title: "إشعارات التطبيق",
    icon: "BellIcon",
    route: "notifictions",
  },
]}
];
